import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Container from "../../../components/Container/Container"
import Title from "../../../components/Title/Title"
import Button from "../../../components/Button/Button"

const Tab = () => {
  const { prismicRooms } = useStaticQuery(graphql`
    query {
      prismicRooms {
        data {
          rooms_tab_bg {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
          rooms_group {
            title {
              text
            }
            room {
              document {
                ... on PrismicRoom {
                  uid
                  data {
                    cover_image {
                      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                    }
                    intro {
                      html
                    }
                  }
                }
              }
            }
            is_image_last
          }
        }
      }
    }
  `)

  const roomsData = prismicRooms.data
  const roomsGroup = roomsData.rooms_group
  const bgImage = getImage(roomsData.rooms_tab_bg)

  // Tabs
  const [activeTab, setActiveTab] = useState(4)

  const handleTabChange = index => {
    setActiveTab(index)
  }

  return (
    <>
      <div className="relative smo">
        <div className="absolute inset-0 ">
          <GatsbyImage
            image={bgImage}
            alt={""}
            className="object-cover h-full"
          />
        </div>
        <div className="relative bg-white bg-opacity-[0.85]">
          <Container className="relative overflow-hidden">
            <ul className="justify-center hidden mb-1 lg:flex tab-nav">
              {roomsGroup.map(({ title }, index) => {
                return (
                  <li
                    className="cursor-pointer py-[10px] mx-[3px] px-8 bg-white text-center uppercase text-[12px] leading-6 tracking-[3px] relative hover:text-primary select-none"
                    key={index}
                    onClick={() => {
                      handleTabChange(index)
                    }}
                  >
                    <span
                      className={`relative z-10  ${
                        activeTab === index ? "text-primary" : ""
                      }`}
                    >
                      {title.text}
                    </span>
                    <span
                      aria-hidden
                      className={`absolute bg-white  left-0 w-full h-full ${
                        activeTab === index ? "-bottom-2" : "bottom-0"
                      }`}
                    ></span>
                  </li>
                )
              })}
              <li>{}</li>
            </ul>
            <div className="tabs lg:flex ">
              {roomsGroup.map(({ title, room, is_image_last }, index) => {
                const roomDoc = room.document
                const roomCover = getImage(roomDoc.data.cover_image)
                return (
                  <div
                    className={`tab-item mb-[6px] bg-white ${
                      activeTab === index ? "active lg:block" : "lg:hidden"
                    }`}
                    key={index}
                  >
                    <div
                      className="tab-button cursor-pointer py-[10px] px-7 lg:hidden"
                      onClick={() => {
                        handleTabChange(index)
                      }}
                    >
                      {title.text}
                    </div>
                    <div
                      className={`tab-content py-[10px] px-[15px] lg:py-0 lg:px-0 sm:px-7 ${
                        activeTab === index ? "active block" : "hidden"
                      }`}
                    >
                      <div className="flex flex-col md:flex-row">
                        {!is_image_last && (
                          <div className="flex-auto w-full mb-4 lg:mb-0 texts md:w-1/2">
                            <GatsbyImage
                              image={roomCover}
                              alt={title.text}
                              className="md:h-full md:object-cover"
                            />
                          </div>
                        )}
                        <div
                          className={`flex-auto w-full texts md:w-1/2 lg:py-[80px] lg:px-[90px] ${
                            !is_image_last ? "md:pl-5" : "md:pr-5"
                          }`}
                        >
                          <div className="">
                            <Title colorClass="text-secondary">
                              {title.text}
                            </Title>
                          </div>
                          <div className="mb-4 lg:mb-8">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: roomDoc.data.intro.html,
                              }}
                            />
                          </div>
                          <div>
                            <Button
                              variant="link"
                              linkTo={`/room/${roomDoc.uid}`}
                              arrowRight
                            >
                              More Details
                            </Button>
                          </div>
                        </div>
                        {is_image_last && (
                          <div className="flex-auto w-full pt-4 texts md:w-1/2 lg:pt-0">
                            <GatsbyImage
                              image={roomCover}
                              alt={title.text}
                              className="md:h-full md:object-cover"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className="flex items-center justify-center pt-10">
              <svg
                x="0px"
                y="0px"
                viewBox="0 0 24 24"
                className="w-7 h-7 text-[28px] opacity-70 mr-[10px]"
              >
                <g fill="currentColor">
                  <path
                    fill="currentColor"
                    d="M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12s12-5.383,12-12S18.617,0,12,0z M14.658,18.284 c-0.661,0.26-2.952,1.354-4.272,0.191c-0.394-0.346-0.59-0.785-0.59-1.318c0-0.998,0.328-1.868,0.919-3.957 c0.104-0.395,0.231-0.907,0.231-1.313c0-0.701-0.266-0.887-0.987-0.887c-0.352,0-0.742,0.125-1.095,0.257l0.195-0.799 c0.787-0.32,1.775-0.71,2.621-0.71c1.269,0,2.203,0.633,2.203,1.837c0,0.347-0.06,0.955-0.186,1.375l-0.73,2.582 c-0.151,0.522-0.424,1.673-0.001,2.014c0.416,0.337,1.401,0.158,1.887-0.071L14.658,18.284z M13.452,8c-0.828,0-1.5-0.672-1.5-1.5 s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S14.28,8,13.452,8z"
                  ></path>
                </g>
              </svg>
              View all rooms by pressing the tabs above
            </div>
          </Container>
        </div>
      </div>
    </>
  )
}

export default Tab
