import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PageCover = ({ imageLocalFile, alt = "", className }) => {
  const coverImage = getImage(imageLocalFile)

  return (
    <>
      <GatsbyImage
        image={coverImage}
        alt={alt}
        className={`h-[300px] md:h-[350px] lg:h-[400px] ${className}`}
      />
    </>
  )
}

export default PageCover
