import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/SEO/SEO"
import Button from "../components/Button/Button"

import Hero from "../sections/rooms/Hero/Hero.js"
import Tab from "../sections/rooms/Tab/Tab"
import Location from "../sections/common/Location/Location"
import Reviews from "../sections/common/Reviews/Reviews"
import Amenities from "../sections/rooms/Amenities/Amenities"

const RoomsPage = ({ data }) => {
  if (!data) return null

  const pageData = data.prismicRooms.data

  return (
    <>
      <SEO
        title={pageData.meta_title.text}
        description={pageData.meta_description.text}
      />
      <section>
        <Hero />
      </section>
      <section>
        <Tab />
      </section>
      <section>
        <Amenities
          amenities_title={pageData.amenities_title_1}
          amenities_text={pageData.amenities_text_1}
          amenities={pageData.amenities_1}
        />
      </section>
      <section>
        <Amenities
          amenities_title={pageData.amenities_title_2}
          amenities_text={pageData.amenities_text_2}
          amenities={pageData.amenities_2}
        />
      </section>
      <section>
        <div className="bg-[#ebebeb] bg-opacity-90 py-[15px] flex justify-center mt-8 mb-5">
          <Button arrowRight linkTo="/booking">
            SEE AVAILABILITY
          </Button>
        </div>
      </section>
      <section>
        <Location />
      </section>
      <section className="pb-5 md:pb-7 lg:pb-10">
        <Reviews />
      </section>
    </>
  )
}

export default RoomsPage

export const query = graphql`
  query {
    prismicRooms {
      data {
        amenities_title_1 {
          text
        }
        amenities_text_1 {
          html
        }

        amenities_1 {
          icon_image {
            gatsbyImageData(width: 48, layout: FIXED)
          }
          amenity_title {
            text
          }
        }

        amenities_title_2 {
          text
        }
        amenities_text_2 {
          html
        }

        amenities_2 {
          icon_image {
            gatsbyImageData(width: 48, layout: FIXED)
          }
          amenity_title {
            text
          }
        }

        meta_title {
          text
        }
        meta_description {
          text
        }
      }
    }
  }
`
