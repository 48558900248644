import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useInView } from "react-intersection-observer"

import Container from "../../../components/Container/Container"
import Title from "../../../components/Title/Title"

const Location = () => {
  const { prismicCommon } = useStaticQuery(graphql`
    query {
      prismicCommon {
        data {
          location_pre_title {
            text
          }
          location_title {
            text
          }
          location_text {
            html
          }
          google_map_embed_url {
            url
          }
          features_title {
            text
          }
          feature_items {
            feature {
              text
            }
          }
        }
      }
    }
  `)

  const commonData = prismicCommon.data
  const featureItems = commonData.feature_items

  const { ref, inView } = useInView({
    /* Optional options */
    rootMargin: "100px",
    triggerOnce: true,
  })

  return (
    <>
      <Container>
        <div className="flex flex-col md:flex-row">
          <div className="flex-auto w-full pr-8 md:w-1/2 lg:pr-20">
            <Title preTitle>{commonData.location_pre_title.text}</Title>
            <Title>{commonData.location_title.text}</Title>
            <div className="pt-3 md:pt-5">
              <div
                dangerouslySetInnerHTML={{
                  __html: commonData.location_text.html,
                }}
              />
            </div>
            <div className="pt-8 pb-1 md:pt-10 md:pb-2">
              <h3 className="text-base md:text-xl text-primary">
                {commonData.features_title.text}
              </h3>
            </div>
            <ul role="list" className="list-disc list-inside">
              {featureItems.map(({ feature }, index) => (
                <li key={index} className="mt-[10px] leading-[1.8em]">
                  {feature.text}
                </li>
              ))}
            </ul>
          </div>
          <div className="flex-auto w-full pt-9 md:w-1/2 md:pt-0" ref={ref}>
            {inView && (
              <div className="h-[300px] md:h-full">
                <iframe
                  src={commonData.google_map_embed_url.url}
                  width="100%"
                  height="100%"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
              </div>
            )}
          </div>
        </div>
      </Container>
    </>
  )
}

export default Location
