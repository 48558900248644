import React from "react"
import { Link } from "gatsby"

import * as styles from "./Button.module.css"

const Button = ({
  variant = "primary",
  linkTo,
  children,
  className,
  arrowRight = false,
  btnType = "button",
}) => {
  let btnVariant = styles.btnPrimary

  if (variant === "link") {
    btnVariant = styles.btnLink
  }

  return (
    <>
      {linkTo ? (
        <Link
          to={linkTo}
          className={`${styles.btn} ${btnVariant} ${className}`}
        >
          {children}
          {arrowRight && (
            <IconArrowRight
              className={`text-base ml-[10px] ${styles.iconArrow}`}
            />
          )}
        </Link>
      ) : (
        <button
          className={`${styles.btn} ${btnVariant} ${className}`}
          type={btnType}
        >
          {children}
          {arrowRight && (
            <IconArrowRight
              className={`text-base ml-[10px] ${styles.iconArrow}`}
            />
          )}
        </button>
      )}
    </>
  )
}

export default Button

const IconArrowRight = ({ className }) => (
  <svg x="0px" y="0px" viewBox="0 0 24 24" className={className}>
    <g>
      <path d="M22.707,11.293L15,3.586L13.586,5l6,6H2c-0.553,0-1,0.448-1,1s0.447,1,1,1h17.586l-6,6L15,20.414 l7.707-7.707C23.098,12.316,23.098,11.684,22.707,11.293z"></path>
    </g>
  </svg>
)
