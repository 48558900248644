import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Container from "../../../components/Container/Container"
import Title from "../../../components/Title/Title"
import PageCover from "../../../components/PageCover/PageCover"

const Hero = () => {
  const { prismicRooms } = useStaticQuery(graphql`
    query {
      prismicRooms {
        data {
          page_pre_title {
            text
          }
          page_title_1 {
            text
          }
          page_title_2 {
            text
          }
          page_intro {
            html
          }
          cover_image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
  `)

  const roomsData = prismicRooms.data

  return (
    <>
      <div>
        <PageCover
          imageLocalFile={roomsData.cover_image}
          alt={roomsData.page_title_1.text}
        />
      </div>
      <Container>
        <div className="flex flex-col items-center text-center">
          <div>
            <Title preTitle>{roomsData.page_pre_title.text}</Title>
          </div>
          <h1 className="flex flex-col items-center sm:flex-row">
            <div className="sm:mr-[-16px]">
              <Title>{roomsData.page_title_1.text}</Title>
            </div>
            <StaticImage
              src="../../../assets/images/and-bg-symbol.png"
              alt="And bg"
              placeholder="none"
              className="hidden sm:block mt-[-90px]"
            />
            <div className="sm:ml-[-24px]">
              <Title>{roomsData.page_title_2.text}</Title>
            </div>
          </h1>
          <div className="pt-3 sm:pt-7 max-w-[630px]">
            <div
              dangerouslySetInnerHTML={{ __html: roomsData.page_intro.html }}
            />
          </div>
        </div>
      </Container>
    </>
  )
}

export default Hero
